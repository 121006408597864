import { api } from "./api";

// bang diem
export const getBangDiemApi = async (id) => {
    const { data } = await api.get(`/bang-diem/${id}`);
    return data;
};




export const themBangDiemApi = async (model) => {
    const { data } = await api.post('/bang-diem', model);
    return data;
};

export const suaBangDiemApi = async (id, model) => {
    const { data } = await api.put(`/bang-diem/${id}`, model);
    return data;
};

export const xoaBangDiemApi = async (id) => {
    const { data } = await api.delete(`/bang-diem/${id}`);
    return data;
};

// bang diem chi tiet
export const getBangDiemChiTietApi = async (userId) => {
    const { data } = await api.get(`bang-diem/bang-diem-chi-tiet/${userId}`);
    return data;
};

export const themBangDiemChiTietApi = async (model) => {
    const { data } = await api.post(`bang-diem/luu-bang-diem-chi-tiet`, model);
    return data;
};


export const getBangDiemChiTietDanhMucApi = async (maDanhMuc) => {
    const { data } = await api.get(`bang-diem/bang-diem-chi-tiet-danh-muc/${maDanhMuc}`);
    return data;
};




// danh muc
export const getDanhMucApi = async () => {
    const { data } = await api.get('/danhmuc');
    return data;
};

export const themDanhMucApi = async (model) => {
    const { data } = await api.post('/danhmuc', model);
    return data;
};

export const suaDanhMucApi = async (id, model) => {
    const { data } = await api.put(`/danhmuc/${id}`, model);
    return data;
};

export const xoaMucApi = async (id) => {
    const { data } = await api.delete(`/danhmuc/${id}`);
    return data;
};






