import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { themBangDiemChiTietApi } from '../api/bangDiemApi';
import { getBangDiemChiTietDis } from '../redux/bangDiemReducer';
import { message } from 'antd';


const FormBangDiemChiTiet = ({ bangDiemChiTiet: { bangDiemChiTiet, bangDiem }, onClose }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schema = yup.object().shape({


  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm
    ({
      resolver: yupResolver(schema),
    });

  let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

  const onSubmit = data => {
    if (data.diem_tu_cham > bangDiem.diem_chuan) {
      message.error("Điểm chấm không được lớn hơn điểm chuẩn !!")
      return;
    }

    let model = {}
    if (bangDiemChiTiet) {
      model = {
        ...bangDiemChiTiet,
        ...data,
        diem_tu_cham: data.diem_tu_cham == "" ? 0 : +data.diem_tu_cham,
      }

    } else {
      model = {
        ...data,
        diem_tu_cham: +data.diem_tu_cham,
        nguoi_dung_id: userLogin.id,
        diem_cap_huyen: 0,
        phan_hoi_cap_huyen: "",
        bang_diem_id: bangDiem.id
      }

    }

    themBangDiemChiTietApi(model).then(res => {

      dispatch(getBangDiemChiTietDis(userLogin.id))
      message.success("Đã thêm nội dung mới !")
      onClose()
    }).catch(err => {
      message.error("Đã xãy ra lỗi !")
    })


  }

  useEffect(() => {

    reset(bangDiemChiTiet)


  }, [bangDiemChiTiet, bangDiemChiTiet?.id])


  return (
    <div>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 col-12">
          <label className="form-label">Điểm tự chấm</label>
          <input className="form-control"  {...register('diem_tu_cham')} />
          {errors.diem_tu_cham && <p className='text-danger'>{errors.diem_tu_cham?.message}</p>}

        </div>
        <div className="mb-3 col-12">
          <label className="form-label">Hoạt động minh chứng</label>
          <textarea rows={10} className="form-control"  {...register('hoat_dong_minh_chung')} />
          {errors.hoat_dong_minh_chung && <p className='text-danger'>{errors.hoat_dong_minh_chung?.message}</p>}

        </div>
        <div className="mb-3 col-12">
          <label className="form-label">Giải trình</label>
          <textarea rows={10} className="form-control"  {...register('giai_trinh')} />
          {errors.giai_trinh && <p className='text-danger'>{errors.giai_trinh?.message}</p>}

        </div>
        <div className="mb-3 col-2">
          <button type="submit" className="btn btn-primary">Lưu</button>
        </div>
        <div className="mb-3 col-3">
          <button type='button' className='btn btn-outline-primary' onClick={() => {
            reset({
              diem_tu_cham: "", hoat_dong_minh_chung: "", giai_trinh: ""
            })
          }}>Làm mới</button>
        </div>

      </form>
    </div>
  )
}


export default FormBangDiemChiTiet