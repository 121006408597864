

import { createSlice } from '@reduxjs/toolkit';
import { getDanhMucKhenThuongApi, getKhenThuongApi, getKhenThuongChiTietApi } from '../api/khenThuongApi';


const initialState = {
  data: [],
  danhSachKhenThuongChiTiet: [],
  danhMuc: [],

};

const khenThuongReducer = createSlice({
  name: 'khenThuongReducer',
  initialState,
  reducers: {

    getKhenThuongActions(state, action) {

      state.data = action.payload;
    },

    getDanhMucActions(state, action) {

      state.danhMuc = action.payload;
    },


    getKhenThuongChiTietActions(state, action) {

      state.danhSachKhenThuongChiTiet = action.payload;
    },


  },
});

export const { getKhenThuongActions, getDanhMucActions,
  getKhenThuongChiTietActions
} = khenThuongReducer.actions;

export default khenThuongReducer.reducer;


export const getKhenThuongDis = (maDanhMuc) => {
  return async (dispatch) => {
    const result = await getKhenThuongApi(maDanhMuc);
    const action = getKhenThuongActions(result);

    if (result) {
      const resultChiTiet = await getKhenThuongChiTietApi(result.id);
      console.log(resultChiTiet)
      const actionChiTiet = getKhenThuongChiTietActions(resultChiTiet);
      dispatch(actionChiTiet);
    }else{
      dispatch(getKhenThuongChiTietActions([]));
    }

    dispatch(action);
  };
};


export const getKhenThuongChiTietDis = (khenThuongId) => {
  return async (dispatch) => {
    const result = await getKhenThuongChiTietApi(khenThuongId);
    const action = getKhenThuongChiTietActions(result);
    dispatch(action);
  };
};

export const getDanhMucKhenThuongDis = () => {
  return async (dispatch) => {
    const result = await getDanhMucKhenThuongApi();
    const action = getDanhMucActions(result);
    dispatch(action);
  };
};



