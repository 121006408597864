import React, { useEffect, useState } from 'react'
import { checkQuyen, convertTextToHtml } from '../utils/config'
import { Collapse, Divider, Drawer, InputNumber, Popconfirm, Result, Switch, Table, Tag, message, notification } from 'antd'
import { getDanhMucDis, getBangDiemDis, getBangDiemChiTietDis, getBangDiemChiTietDanhMucDis } from '../redux/bangDiemReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { suaBangDiemApi, themBangDiemApi, xoaBangDiemApi } from '../api/bangDiemApi';
import FormDiemBangDiem from './FormDiemBangDiem';
import FormBangDiemChiTiet from './FormBangDiemChiTiet';
import HuyenChamDiem from './HuyenChamDiem';

const NoiDungBangDiem = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data } = useSelector((state) => state.bangDiemReducer);
    const { dataBangDiemChiTiet } = useSelector((state) => state.bangDiemReducer);

    const [bangDiem, setBangDiem] = useState({ id: 0 })

    const [open, setOpen] = useState(false);
    const [openChamDiem, setOpenChamDiem] = useState(false);

    const onClose = () => {
        setOpen(false);
        setOpenChamDiem(false)
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotification = () => {
        api.success({
            message: 'Đã lưu !',
            showProgress: true,
            duration: 1
        });
    }



    const { id } = useParams()

    let userLogin = JSON.parse(localStorage.getItem("LOGIN_USER"))

    const [bangDiemChiTiet, setBangDiemChiTiet] = useState(null)


    const xuatTableBangDiem = (maCha) => {
        let columns = []

        let tongDiemChuan = 0;
        let tongDiemTuCham = 0;
        let tongDiemCapHuyen = 0;


        let dataTable = data?.filter(n => n.diem_chuan != 0 && n.bang_diem_cha == maCha)

        dataTable.map(item => {
            tongDiemChuan += item.diem_chuan
            dataBangDiemChiTiet.filter(n => n.bang_diem_id == item.id).map(n => {
                tongDiemTuCham += n.diem_tu_cham
                tongDiemCapHuyen += n.diem_cap_huyen

            })
        })

        if (checkQuyen()) {
            columns = [
                {
                    title: 'Tiêu đề',
                    key: 'tieu_de',
                    render: (_, record) => {
                        return <>
                            {record.tieu_de}
                        </>
                    }
                },
                {
                    title: 'Nội dung',
                    key: 'noi_dung',
                    render: (_, record) => {
                        return <>
                            {convertTextToHtml(record.noi_dung)}
                        </>
                    }
                },
                {
                    title: 'Điểm chuẩn',
                    key: 'diem_chuan',
                    render: (_, record) => {
                        return <>
                            <b><Tag color='blue'> {record.diem_chuan}</Tag></b>
                        </>
                    }
                },
                {
                    title: ' ',
                    key: 'action',
                    render: (_, record) => {
                        return <>

                            <label>Khóa chấm điểm: <Switch checked={record.da_an} onChange={(value) => {
                                let model = { ...record, da_an: value }
                                suaBangDiemApi(model.id, model).then(res => {
                                    dispatch(getBangDiemDis(id))
                                })
                            }} /></label>
                            <br />

                            <button className='btn btn-sm btn-primary m-2' onClick={() => {
                                setBangDiem({
                                    ...record
                                })
                                setOpen(true)
                            }}>
                                <i className='fas fa-edit'></i>
                            </button>

                            {record.dc_xoa && <Popconfirm
                                title="Bạn chắc muốn tiếp tục ?"
                                onConfirm={() => {
                                    xoaBangDiemApi(record.id).then(res => {
                                        dispatch(getBangDiemDis(id))

                                    })
                                }}
                                okText="Có"
                                cancelText="Không"
                            >
                                <button className='btn btn-sm btn-danger m-2'>
                                    <i className='fas fa-trash'></i>
                                </button>

                            </Popconfirm>
                            }
                        </>
                    }
                }
            ]
        } else {
            columns = [
                // {
                //     title: 'id',
                //     key: 'tieu_de',
                //     dataIndex: "id"
                // },
                {
                    title: 'Tiêu đề',
                    key: 'tieu_de',
                    render: (_, record) => {
                        return <>
                            {record.tieu_de}
                        </>
                    }
                },
                {
                    title: 'Nội dung',
                    key: 'noi_dung',
                    render: (_, record) => {
                        return <>
                             {convertTextToHtml(record.noi_dung)}
                        </>
                    }
                },
                {
                    title: <> Điểm chuẩn <Tag color='red'>{tongDiemChuan}</Tag> </>,
                    key: 'diem_chuan',
                    render: (_, record) => {
                        return <>
                            <b><Tag color='blue'>  {record.diem_chuan}</Tag></b>
                        </>
                    }
                },
                {
                    title: <> Điểm tự chấm <Tag color='red'>{tongDiemTuCham}</Tag></>,
                    key: 'diem_tu_cham',
                    render: (_, record) => {
                        let tempDiemChiTiet = dataBangDiemChiTiet.find(n => n.bang_diem_id == record.id);
                        return <b>
                            <Tag color='blue'>

                                {
                                    tempDiemChiTiet && tempDiemChiTiet.diem_tu_cham

                                }
                            </Tag>
                        </b>
                    }
                },
                {
                    title: 'Hoạt động minh chứng',
                    key: 'hoat_dong_minh_chung',
                    render: (_, record) => {
                        let tempDiemChiTiet = dataBangDiemChiTiet.find(n => n.bang_diem_id == record.id);
                        return <b>
                            {
                                tempDiemChiTiet && tempDiemChiTiet.hoat_dong_minh_chung
                            }
                        </b>
                    }
                },
                {
                    title: 'Giải trình',
                    key: 'giai_trinh',
                    render: (_, record) => {
                        let tempDiemChiTiet = dataBangDiemChiTiet.find(n => n.bang_diem_id == record.id);
                        return <b>
                            {
                                tempDiemChiTiet && tempDiemChiTiet.giai_trinh
                            }
                        </b>
                    }
                },
                {
                    title: <> Phản hồi cấp huyện <Tag color='red'>{tongDiemCapHuyen}</Tag></>,
                    key: 'phan_hoi_cap_huyen',
                    render: (_, record) => {
                        let tempDiemChiTiet = dataBangDiemChiTiet.find(n => n.bang_diem_id == record.id);
                        return <>

                            <label>Điểm: <b>
                                <Tag color='blue'>
                                    {tempDiemChiTiet?.diem_cap_huyen}
                                </Tag>
                            </b> </label>
                            <br />
                            <label>Phản hồi: <b>{tempDiemChiTiet?.phan_hoi_cap_huyen}</b> </label>

                        </>
                    }
                },
                {
                    title: ' ',
                    key: 'action',
                    render: (_, record) => {
                        return <>
                            {!record.da_an && <button className='btn btn-sm btn-success m-2' onClick={() => {

                                let tempDiemChiTiet = dataBangDiemChiTiet.find(n => n.bang_diem_id == record.id);

                                setBangDiemChiTiet({
                                    bangDiemChiTiet: tempDiemChiTiet, bangDiem: record
                                })
                                setOpenChamDiem(true)
                            }}>
                                <i className='fas fa-edit'></i> Chấm điểm
                            </button>
                            }
                        </>
                    }
                }
            ]
        }
        let items = []
        data?.filter(n => n.diem_chuan == 0 && n.bang_diem_cha == maCha).map(item => {

            items.push({
                key: item.id,
                label: <>{item.tieu_de}

                    {checkQuyen() && <>
                        <Popconfirm
                            title="Thay đổi tiêu đề ?"
                            description={<>
                                <input className='form-control' id="txt-tieuDe" />
                            </>}
                            onConfirm={() => {
                                if (document.querySelector("#txt-tieuDe").value != '') {
                                    let model = {
                                        ...item,
                                        tieu_de: document.querySelector("#txt-tieuDe").value
                                    }
                                    suaBangDiemApi(item.id, model).then(res => {

                                        dispatch(getBangDiemDis(id))

                                    })
                                }


                            }}
                            okText="Có"
                            cancelText="Không"
                        >
                            <Tag className='mx-2' color='blue' onClick={() => {

                            }}><i className='fas fa-edit'></i></Tag>
                        </Popconfirm>

                        {!data?.find(n => n.bang_diem_cha == item.id) &&
                            <Popconfirm
                                title="Bạn chắc muốn tiếp tục ?"
                                onConfirm={() => {
                                    xoaBangDiemApi(item.id).then(res => {

                                        dispatch(getBangDiemDis(id))

                                    })

                                }}
                                okText="Có"
                                cancelText="Không"
                            >
                                <Tag color='red'><i className='fas fa-trash'></i></Tag>
                            </Popconfirm>}
                    </>}
                </>,
                children: <>
                    {checkQuyen() &&
                        <>

                            <button className='btn btn-warning btn-sm my-2' onClick={() => {
                                setBangDiem({
                                    id: 0,
                                    tieu_de: "",
                                    noi_dung: "",
                                    ma_danh_muc: item.ma_danh_muc,
                                    bang_diem_cha: item.id,
                                    diem_chuan: 0,
                                    da_an: false
                                })
                                setOpen(true)

                            }} ><i className='fas fa-plus'></i>  Thêm nội dung chấm điểm </button>

                        </>
                    }
                    {xuatTableBangDiem(item.id)}
                </>,
            })
        })

        return <>

            <Collapse items={items} />

            <Divider />
            {dataTable.length > 0 && <Table columns={columns} dataSource={dataTable}
                rowKey="id"
                expandable={checkQuyen() && {
                    expandedRowRender: (record) => {
                        return <HuyenChamDiem bangDiem={record} />
                    }
                }}
            />}

        </>
    }

    const items = [];
    const xuatTableCha = (maCha) => {
        data?.filter(n => n.bang_diem_cha == maCha).map(item => {

            items.push({
                key: item.id,
                label: <>{item.tieu_de}

                    {checkQuyen() && <>
                        <Popconfirm
                            title="Thay đổi tiêu đề ?"
                            description={<>
                                <input className='form-control' id="txt-tieuDe" />
                            </>}
                            onConfirm={() => {
                                if (document.querySelector("#txt-tieuDe").value != '') {
                                    let model = {
                                        ...item,
                                        tieu_de: document.querySelector("#txt-tieuDe").value
                                    }
                                    suaBangDiemApi(item.id, model).then(res => {

                                        dispatch(getBangDiemDis(id))

                                    })
                                }


                            }}
                            okText="Có"
                            cancelText="Không"
                        >
                            <Tag className='mx-2' color='blue' onClick={() => {

                            }}><i className='fas fa-edit'></i></Tag>
                        </Popconfirm>

                        {!data?.find(n => n.bang_diem_cha == item.id) &&
                            <Popconfirm
                                title="Bạn chắc muốn tiếp tục ?"
                                onConfirm={() => {
                                    xoaBangDiemApi(item.id).then(res => {

                                        dispatch(getBangDiemDis(id))

                                    })

                                }}
                                okText="Có"
                                cancelText="Không"
                            >
                                <Tag color='red'><i className='fas fa-trash'></i></Tag>
                            </Popconfirm>}
                    </>}
                </>,
                children: <>
                    {checkQuyen() &&
                        <>
                            <button className='btn btn-success btn-sm mx-2' onClick={() => {
                                let model = {
                                    noi_dung: "",
                                    tieu_de: "Nội dung mới",
                                    ma_danh_muc: Number(id),
                                    bang_diem_cha: item.id,
                                    diem_chuan: 0,
                                    da_an: false
                                }

                                themBangDiemApi(model).then(res => {

                                    dispatch(getBangDiemDis(id))
                                    message.success("Đã thêm nội dung mới !")
                                })

                            }} ><i className='fas fa-plus'></i>  Thêm nhóm nội dung </button>

                            <button className='btn btn-warning btn-sm my-2' onClick={() => {
                                setBangDiem({
                                    id: 0,
                                    tieu_de: "",
                                    noi_dung: "",
                                    ma_danh_muc: item.ma_danh_muc,
                                    bang_diem_cha: item.id,
                                    diem_chuan: 0,
                                    da_an: false
                                })
                                setOpen(true)

                            }} ><i className='fas fa-plus'></i>  Thêm nội dung chấm điểm </button>

                        </>
                    }

                    {xuatTableBangDiem(item.id)}
                </>,
            })
        })
        return <Collapse items={items} />
    }



    useEffect(() => {
        dispatch(getBangDiemDis(id))
        dispatch(getBangDiemChiTietDis(userLogin.id))
        dispatch(getBangDiemChiTietDanhMucDis(id))
    }, [id])



    return (
        <div>
            {contextHolder}
            {checkQuyen() && <button className='btn btn-success btn-sm my-2' onClick={() => {
                let model = {
                    noi_dung: "",
                    tieu_de: "Nội dung mới",
                    ma_danh_muc: Number(id),
                    bang_diem_cha: 0,
                    diem_chuan: 0,
                    da_an: false
                }

                themBangDiemApi(model).then(res => {

                    dispatch(getBangDiemDis(id))
                    message.success("Đã thêm nội dung mới !")
                })

            }} ><i className='fas fa-plus'></i>  Thêm nhóm nội dung </button>}
            {
                data.length > 0 ?
                    xuatTableCha(0)
                    :
                    <Result
                        status="404"
                        title="404"
                        subTitle="Không có dữ liệu !"
                    />
            }

            <Drawer
                title={`Nội dung chấm điểm`}
                placement="right"
                width={"50%"}
                onClose={onClose}
                open={open}
            >
                <FormDiemBangDiem bangDiem={bangDiem} onClose={onClose} />
            </Drawer>

            <Drawer
                title={`Chấm điểm`}
                placement="right"
                width={"30%"}
                onClose={onClose}
                open={openChamDiem}
            >
                <FormBangDiemChiTiet bangDiemChiTiet={bangDiemChiTiet} onClose={onClose} />
            </Drawer>
        </div>
    )
}

export default NoiDungBangDiem