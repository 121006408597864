import axios from 'axios';
// export const BE_DOMAIN = 'http://localhost:8080'
// export const BE_DOMAIN_FILE = 'http://localhost:8080/public/files/'
// export const BE_DOMAIN_IMG = 'http://localhost:8080/public/imgs/'

export const BE_DOMAIN = 'https://api.tomtatfilm.com'
export const BE_DOMAIN_FILE = 'https://api.tomtatfilm.com/public/files/'
export const BE_DOMAIN_IMG = 'https://api.tomtatfilm.com/public/imgs/'

export const api = axios.create({
    baseURL: BE_DOMAIN, // Thay thế bằng URL của bạn
});

export const fetchExampleData = async () => {
    const { data } = await api.get('/example-endpoint');
    return data;
};

export const loginAPI = async (model) => {
    const { data } = await api.post('/nguoi-dung/login', model);
    return data;
};



export const getTinTucAPI = async () => {
    const { data } = await api.get('/tin-tuc');
    return data;
};

export const getTinTucDetailAPI = async (id) => {
    const { data } = await api.get(`/tin-tuc/${id}`);
    return data;
};

export const uploadImageAPI = async (formData) => {
    const { data } = await api.post(`/file/upload`, formData);

    return data;
}

export const createTinTucAPI = async (model) => {
    const { data } = await api.post(`/tin-tuc`, model);

    return data;
}

export const updateTinTucAPI = async (id, model) => {
    const { data } = await api.put(`/tin-tuc/${id}`, model);

    return data;
}

export const removeTinTucAPI = async (id) => {
    const { data } = await api.delete(`/tin-tuc/${id}`);

    return data;
}



export const doiMatKhauAPI = async (model) => {
    const { data } = await api.post(`/nguoi-dung/doi-mat-khau`, model);

    return data;
}



// cau hinh

export const getCauHinhAPI = async (maCauHinh) => {
    const { data } = await api.get(`/cau-hinh/${maCauHinh}`);
    return data;
};

export const suaCauHinhAPI = async (maCauHinh, { gia_tri }) => {
    const { data } = await api.put(`/cau-hinh/${maCauHinh}`, { gia_tri });
    return data;
};